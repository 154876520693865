bui<template>
  <Layout class="Dashboard-layout">
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        :inline="true"
      >
        <el-form-item prop="idc_id">
          <el-select
            v-model="formData.idc_id"
            v-loading="waitting"
            clearable
            :disabled="waitting"
            placeholder="请选择机房"
          >
            <el-option
              v-for="item in idcList"
              :key="item.idcId"
              :label="item.idcTitle + `(${item.idcId})`"
              :value="item.idcId"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="image">
          <el-select
            v-model="formData.image"
            clearable
            :disabled="!imageList.length"
            placeholder="请选择语言"
          >
            <el-option
              v-for="item in imageList"
              :key="item.image_language"
              :label="item.image_language"
              :value="item.image_language"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            :picker-options="pickerOptions"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="queryData"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="text"
          @click="handleViewLog"
        >
          查看机器使用日志
        </el-button>
      </template>
    </BHeader>
    <div
      v-loading="loading"
      class="wrapper"
    >
      <MixLineBarChart
        ref="chart"
        height="auto"
        :legend-list="legendList"
        :chart-data="chartData"
        :x-axis-data="xAxisData"
      />
    </div>
  </Layout>
</template>

<script>
import MixLineBarChart from '@/views/resources/machine-v2/components/MixLineBarChart'
import { queryIdcList } from '@/api/machine.js'
import { format } from 'fecha'
import { cloneDeep } from 'lodash'
import { pickerShortcutsFactory } from '@/utils/common.js'
import { getMachineChart } from '@/api/log.js'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const shortcuts = pickerShortcutsFactory(dateFormat)

export default {
  name: 'Dashboard',
  components: { MixLineBarChart },
  data () {
    const validatorDate = (rule, value, callback) => {
      if (!value || !value.length || !value[0]) {
        callback(new Error('时间范围必选'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        idc_id: '',
        image: '',
        dateRange: [
          format(new Date().getTime() - 5 * 60 * 1000, dateFormat),
          format(new Date().getTime(), dateFormat)
        ]
      },
      chartData: [],
      xAxisData: [],
      legendList: ['排队人数', '使用中', '已分配', '待分配', '重置中'],
      rules: {
        idc_id: [
          { required: true, message: '机房必选' }
        ],
        image: [
          { required: true, message: '语言必选' }
        ],
        dateRange: [
          { required: true, validator: validatorDate }

        ]
      },
      waitting: true,
      idcList: [],
      loading: false,
      pickerOptions: {
        shortcuts
      }
    }
  },
  computed: {
    imageList () {
      if (!this.formData.idc_id) return []
      return (this.idcList.filter(item => {
        return item.idcId === this.formData.idc_id
      })[0] || {}).image || []
    }
  },
  watch: {
    'formData.idc_id' (val, oldVal) {
      if (val !== oldVal && oldVal) {
        this.formData.image = undefined
      }
    }
  },
  created () {
    this.queryIdcList()
  },
  methods: {
    queryIdcList () {
      queryIdcList({})
        .then(res => {
          this.idcList = res.data.list
          if (this.idcList.length) {
            this.formData.idc_id = this.idcList[0].idcId
            this.formData.image = this.idcList[0].image[0].image_language
            this.queryData()
          }
        })
        .finally(() => {
          this.waitting = false
        })
    },
    queryData () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = cloneDeep(this.formData)
          /* eslint-disable camelcase */
          const [start, end] = formData.dateRange
          delete formData.dateRange
          this.loading = true
          const start_time = format(new Date(start).getTime() - 8 * 60 * 60 * 1000, dateFormat)
          const end_time = format(new Date(end).getTime() - 8 * 60 * 60 * 1000, dateFormat)
          getMachineChart({
            ...formData,
            start_time,
            end_time
          }).then(res => {
            const data = res.data.list || []
            this.xAxisData = []
            this.chartData[0] = []
            this.chartData[1] = []
            this.chartData[2] = []
            this.chartData[3] = []
            this.chartData[4] = []
            data.forEach(item => {
              const {
                time,
                queue_num,
                had_use_num,
                had_allocate_num,
                ready_allocate_num,
                cannot_use_num
              } = item
              this.xAxisData.push(format(new Date(time).getTime() + 8 * 60 * 60 * 1000, 'MM-DD HH:mm'))
              this.chartData[0].push(queue_num)
              this.chartData[1].push(had_use_num)
              this.chartData[2].push(had_allocate_num)
              this.chartData[3].push(ready_allocate_num)
              this.chartData[4].push(cannot_use_num)
            })
            this.$nextTick(() => {
              this.$refs.chart.setOptions()
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleViewLog () {
      this.$router.push({
        name: 'MachineLogV2'
      })
    }
  }
}
</script>

<style lang="less">
  .Dashboard-layout{
    .layout-content{
      display: flex;
      flex-direction: column;
      .wrapper{
        flex: 1;
        display: flex;
        .chart{
          flex: 1;
        }
      }
    }
  }
</style>
