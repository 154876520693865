import { format } from 'fecha'

export const pickerShortcutsFactory = function (dateFormat = 'YYYY-MM-DD HH:mm:ss') {
  return [
    {
      text: '五分钟',
      onClick (picker) {
        const start = format(new Date().getTime() - 5 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '十五分钟',
      onClick (picker) {
        const start = format(new Date().getTime() - 15 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '三十分钟',
      onClick (picker) {
        const start = format(new Date().getTime() - 30 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '一小时',
      onClick (picker) {
        const start = format(new Date().getTime() - 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '四小时',
      onClick (picker) {
        const start = format(new Date().getTime() - 4 * 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '今天',
      onClick (picker) {
        const start = format(new Date(new Date().toLocaleDateString()), dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '一天',
      onClick (picker) {
        const start = format(new Date().getTime() - 24 * 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '本周',
      onClick (picker) {
        const n = (new Date().getDay() || 7) - 1
        const start = format(new Date(new Date().toLocaleDateString()).getTime() - n * 24 * 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '一周',
      onClick (picker) {
        const start = format(new Date().getTime() - 7 * 24 * 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '本月',
      onClick (picker) {
        const n = new Date().getDate() - 1
        const start = format(new Date(new Date().toLocaleDateString()).getTime() - n * 24 * 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '一月',
      onClick (picker) {
        const start = format(new Date().getTime() - 30 * 24 * 60 * 60 * 1000, dateFormat)
        const end = format(new Date().getTime(), dateFormat)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}
