<template>
  <div
    :class="[className, 'line-charts-wrapper']"
    :style="{height:height,width:width}"
  />
</template>

<script>
import * as echarts from 'echarts'
import resize from '@/mixins/resize'
// require('echarts/theme/macarons')

const computedItemData = (name, data, index) => {
  if (!index) {
    return {
      type: 'line',
      yAxisIndex: 1,
      name,
      data
    }
  } else {
    return {
      type: 'bar',
      name,
      data
    }
  }
}
export default {
  name: 'MixLineBarChart',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '450px'
    },
    legendList: {
      type: Array,
      default: () => []
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    },
    xAxisData: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler () {
        this.setOptions()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el)
      this.setOptions(this.chartData)
    },
    setOptions () {
      const chartData = this.chartData
      const xAxisData = this.xAxisData
      const legendList = this.legendList

      const series = () => {
        return chartData.map((item, index) => {
          return computedItemData(legendList[index], item, index)
        })
      }
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          right: 50,
          feature: {
            // dataView: { show: true, readOnly: false },
            dataZoom: {
              yAxisIndex: 'none'
            }
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true }
            // saveAsImage: { show: true }
          }
        },
        legend: {
          data: legendList,
          bottom: 20
        },

        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '机器数量/台',
            minInterval: 1,
            splitLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value} 台'
            }
          },
          {
            type: 'value',
            name: '排队人数/人',
            minInterval: 1,
            splitLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value} 人'
            }
          }
        ],
        series: [
          ...series()
        ]
      })
    }
  }
}
</script>

<style scoped lang="less">
  .line-charts-wrapper{
    padding: 20px 20px;
    box-sizing: border-box;
  }
</style>
